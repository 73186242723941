import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TeamMember from '../components/teamMember';

// import Dots from '../images/team/dots.svg'
import EdwinHo from '../images/team/Edwin-Ho.png'
import MasonPeck from '../images/team/Mason-Peck.png'
import Betty from '../images/team/Betty.png'
import Joyce from '../images/team/Joyce.png'
import Patrick from '../images/team/Patrick.png'
import DanB from '../images/team/DanB.png'
import Michael from '../images/team/Michael.png'
import Matt from '../images/team/matt.png'
import Brian from '../images/team/Brian.png'
import Puneet from '../images/team/Puneet.png'
import AnkurBorah from '../images/team/Ankur-Borah.png'
import Ferdi from '../images/team/Ferdi.png'
import Dagz from '../images/team/Dagz.png'
import AK from '../images/team/AK.png'
import Franklin from '../images/team/Franklin.png'
import Peter from '../images/team/Peter.png'
import Nitish from '../images/team/nitish.png'
import Suvrat from '../images/team/Suvrat.png'

function team() {  
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Team" />
            <Container className="py-5">
                <Row className="justify-content-center text-center mb-5">
                    <Col lg={12} className="mb-3">
                        <div className="section-title">
                            <div>Meet The Team</div>
                            <h3>Meet The Team</h3>
                        </div>
                    </Col>

                    <Col lg={8} md={10} className="mb-md-5">
                        <p>Meet the Team behind HedgePay. 15 people in 5 departments are dedicated to bringing to success to all members of the HPAY family. Development, operations, communication, sales and partnership departments are all working passionately in all our departments to achieve this goal.</p>
                    </Col>
                </Row>
                
                <div className="team-group">
                    <Row className='justify-content-center'>
                        
                    <TeamMember pic={MasonPeck} name="Mason Peck" designation="Founder & CEO" telegram="https://t.me/HedgePayCEO" twitter="https://twitter.com/HedgePayCEO" linkedin="https://www.linkedin.com/in/hedgepayceo/" github="" gitlab="" content="Mason has been an innovator in business ownership for the past 10 years. He has directed several businesses to financial success, working side-by-side with employees, and focusing on active innovation to improve the overall adoption if new technologies. Mason hold a Master of science degree and has personally owned 3 businesses. Mason has the drive, the vision, and the real-life experience to bring about the widespread adoption of HedgePay's international business strategies. Let's welcome Mason to HedgePay!" />
                    <TeamMember pic={DanB} name="Dan B" designation="Co Founder & Dev" telegram="https://t.me/TheGreenVixen" twitter="https://twitter.com/0xVersion" linkedin="" github="https://github.com/hedgepaybsc/" gitlab="" content="Software engineer and blockchain consultant with over 7 years of experience developing fintech and blockchain applications.
Has a bachelor's degree in computer science with a specialization in distributed computing, deep learning and blockchain development. Architected and helped implement the HedgeFi protocol and adjacent HedgePay services." />
                    <TeamMember pic={EdwinHo} name="Edwin Ho" designation="Co Founder & Director" telegram="https://t.me/HedgePay_Ed" twitter="https://twitter.com/edwinho_kc" linkedin="www.linkedin.com/in/edwinhokeecheong" github="" gitlab="" content="For the last 17 years, Edwin has spent his time collaborating with all hierarchies of the digital industry. From startups to multinational corporations, Edwin has constructed innovative marketing strategies that transcend geographical barriers. His outreach has established prosperous and immutable relationships between clients and consumers. Always looking forward, Edwin sees a new age of marketing coming, that will interlace cryptocurrencies with purchases in our daily lives. HedgePay welcomes Edwin to become one of the pioneers that will establish this new economy." />
                    <TeamMember pic={Betty} name="Betty" designation="Hedge Fund Manager" telegram="https://t.me/HedgePayManager" twitter="" linkedin="" github="" gitlab="" content="Betty has been an entrepreneur since 2015 when she first left her 48 hr a week job to strike out on her own. From insurance adjusting to quality control she has spent the past years cultivating experience that will help lead us in the new century of cryptocurrency innovation. Let's all welcome her to HedgePay." />
                    <TeamMember pic={Joyce} name="Joyce Lai" designation="Account Manager" telegram="https://t.me/I_am_LSS" twitter="https://twitter.com/SiewShanLai1" linkedin="https://www.linkedin.com/in/siewshan/" github="" gitlab="" content="Joyce Lai graduated with a Bachelor’s in finance studies 3 months ago. She has been handling the accounts and collecting receipts from everyone to submit for income tax in the month of December 2022." />
                    <TeamMember pic={Patrick} name="Patrick" designation="Listing Manager" telegram="https://t.me/patl1515" twitter="" linkedin="" github="" gitlab="" content="Patrick, with his 12 years of experience in advertising and marketing, has worked within multiple industries bringing a fresh new approach to whomever he has partnered with.  He holds degrees in Graphic Design, Business Marketing and technical certifications in a multitude of design software.  Patrick also has years of direct sales in the New York metro area specializing in small and large unit sales.  With knowledge of working with small start-ups and micro business launches, he hopes to bring a new look and apprach to Hedgepay." />
                    <TeamMember pic={Brian} name="Brian" designation="Frontend Dev" telegram="https://t.me/brianchzy" twitter="" linkedin="https://www.linkedin.com/in/brian-chang-a0788359" github="" gitlab="" content="Brian has built and advised hundreds of startups and turned them into established SMEs. Brian is a Marketing Degree graduate from Leeds Metropolitan in the United Kingdom and a self-taught developer. Brian combines the two fields to provide a common understanding between developer and marketing, develop a practical solution and enhance user experiences." />
                    <TeamMember pic={Puneet} name="Puneet" designation="Frontend Dev" telegram="" twitter="" linkedin="" github="https://github.com/hedgepaybsc/" gitlab="" content="HedgePay's development team is currently under a Non-Disclosure Agreement (NDA), and we have chosen not to disclose the names of those involved due to security purposes. HedgePay always places security as a core tenant and we keep our developments in-house. This works to protect our investors and developers from compromise." />
                    <TeamMember pic={Nitish} name="Nitish" designation="Backend Dev" telegram="https://t.me/NitishBeejawat" twitter="https://twitter.com/NitishBeejawat?t=X1GfTOOeVXUwAmU3AAKUag&s=09" linkedin="https://www.linkedin.com/in/nitish-beejawat-89a928108" github="" gitlab="" content="Nitish is a Full Stack Blockchain Developer. He has around 3 years of experience in blockchain development. He has worked with Bitcoin, Ripple, Polygon, Ethereum, BSC, Tron, Avalanche etc. Developing cryptocurrencies, ICO, IPO, erc20 tokens, stable coins, De-Fi apps, decentralized exchange, NFT platforms, liquidity pools, crypto lending websites and much more. He has a strong hold in solidity, and web3 technologies. He keeps himself up to date with the latest trends in the De-Fi space. Proficient at performing as well as working in a group or independently at a fast-paced setting. He has a deep understanding of distributed ledger, blockchain safety, blockchain design principles, and various blockchain protocols and environments." />
                    <TeamMember pic={Dagz} name="Dagz" designation="Operations" telegram="https://t.me/DagzyBunny" twitter="https://twitter.com/DagzCryptoLife" linkedin="https://www.linkedin.com/in/christianlaput/" github="" gitlab="" content="I've been a Community Manager & Chat Moderator for a year and now I work Operations at HedgePay for 4 months. I love how Ed and the team manages their work and I can see our future full of surprises here. I'm also here to ensure the growth of the HedgePay Philippines community as well." />
                    <TeamMember pic={AnkurBorah} name="Ankur Borah" designation="Operations" telegram="https://t.me/SirMarshal" twitter="https://twitter.com/ankurborahbnb" linkedin="https://www.linkedin.com/in/ankurborah13/" github="" gitlab="" content="Ankur is from India and has many years of experience in Blockchain Domain. He has worked with multiple start-ups, building a community from scratch, growing it organically, and exponentially. Worked with DeFi such as Titano Forks and auto-stake rebase projects, NFT Gaming Projects, Memecoins and Metaverse projects. Have deep understanding in IDO launchpads, INO, Staking Vaults, NFT Staking and Auto-Staking Rebase. He is currently working in the Operations department with HedgePay for the past 3 months." />
                    <TeamMember pic={Ferdi} name="Ferdi" designation="Operations" telegram="https://t.me/kingferdii" twitter="https://twitter.com/ferdi2107?t=M1Dry_o5xHdAvKqgtUS9Uw&s=09" linkedin="https://www.linkedin.com/in/ferdi-nan-b09a33222" github="" gitlab="" content="Ferdi comes from Indonesia and has graduated with a Bachelor's degree in Informatics Engineering from the Padang State University (UNP) West Sumatra." />
                    <TeamMember pic={AK} name="AK" designation="Sales & Operations" telegram="https://t.me/Marketing_AK" twitter="https://twitter.com/therealayush1" linkedin="https://www.linkedin.com/in/ayush-kayastha-9a49711bb" github="" gitlab="" content="I have been studying for a Bachelor's in Computer and Information Technology for almost 4 years in Nepal. I got interested in cryptocurrency around a couple of years ago and since then I have been learning more and more about it. I specialize in providing marketing services for BSC projects and have been a core team member at HedgePay for 4 months." />
                    <TeamMember pic={Peter} name="Peter" designation="Investment" telegram="https://t.me/Irony_128" twitter="https://twitter.com/Peter94448348" linkedin="" github="" gitlab="" content="Peter is German, but lives in the French-speaking part of Switzerland. He is no professional but passionate about blockchain and crypto. He dived into crypto in 2017, but sold his Bitcoin way too early. He also works in the medical services and his hobbies include investing and trading." />
                    <TeamMember pic={Matt} name="Matt" designation="Communications" telegram="https://t.me/Matt2" twitter="https://twitter.com/mattstewart12" linkedin="www.linkedin.com/in/matt-stewart-8896a4150" github="" gitlab="" content="My name is Matt , I have been in crypto for 3+ years. Play Crown Green Bowls in past time and love football ." />
                    <TeamMember pic={Franklin} name="Franklin" designation="Communications" telegram="https://t.me/Franklin5522" twitter="https://twitter.com/NduOxford" linkedin="https://www.linkedin.com/in/ndu-boy-franklin-30610923a" github="" gitlab="" content="I am Franklin, a medical student studying Human Physiology in NICOSIA DISTRICT (Lefkoşa), Cyprus. I am also a crypto lover, got interested a few years ago, have been doing research, and learning more about Crypto. I work mostly on BSC and ETH projects, providing services like public relations and communication services and it's really an honor to be working with the HedgePay team." />
                    <TeamMember pic={Michael} name="Michael" designation="Operations" telegram="https://t.me/Michaeloneday" twitter="https://twitter.com/Michael_1day" linkedin="https://www.linkedin.com/in/michael-oliveros-555575235/" github="" gitlab="" content="Michael has been very active and committed to HedgePay. He works as a counselor during his day job and day & night at HedgePay. Let's all welcome Michael again for his hardwork in the HedgePay family!" />
                    <TeamMember pic={Suvrat} name="Suvrat" designation="UI UX Designer" telegram="https://t.me/Suvratkapoor" twitter="" linkedin="https://www.linkedin.com/in/suvrat-kapoor-75533424a/" github="" gitlab="" content="Suvrat completed his Bachelor’s of Engineering in Civil. He worked as a Site Engineer for 2 years for a corporate company. Alongside he followed his passion in designing and  did certification in Figma and UI/UX from Udemy and Coursera . He is now working as an UI and graphic designer Intern for Websetters. " />
                    {/* <TeamMember pic={Sapphire} name="Sapphire" designation="Communication" telegram="https://t.me/sapphyy" twitter="https://twitter.com/" linkedin="" github="" gitlab="" content="She works with HedgePay as Head of Communications. Her major tasks include article writing, HPAY news publications, organizing quizzes and moderating the community." /> */}

                        
                        
                        
                        {/* <Col xs={12} sm={6} md={4} className="mb-md-5 mb-sm-4 mb-3">
                            <Card className='team-card text-sm-center'>
                                <Card.Body>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="dropdown-reset" id="dropdown-basic">
                                            <img src={Dots} alt="dots" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  align="right">
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="team-card-image">
                                        <img src={EdwinHo} alt="Edwin Ho" />
                                    </div>
                                    <div className="team-card-info">
                                        <h3>Edwin Ho</h3>
                                        <h5><span>Founder & Director</span></h5>

                                        <div className="team-social">
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.61864 12.9513L16.7717 18.9749C17.7022 19.4883 18.3735 19.2224 18.6053 18.1112L21.9241 2.47215C22.2638 1.10993 21.4048 0.491875 20.5146 0.895989L1.02712 8.4103C-0.303071 8.94389 -0.295147 9.68603 0.784683 10.0166L5.78562 11.5776L17.3633 4.27339C17.9099 3.94194 18.4116 4.11997 17.9999 4.48548" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.91856 17.9382C15.2205 17.9382 19.7613 11.0601 19.7613 5.09544C19.7613 4.90008 19.7613 4.7056 19.7481 4.512C20.6315 3.87304 21.394 3.08189 22 2.1756C21.1763 2.5408 20.3024 2.78019 19.4075 2.88576C20.3498 2.32172 21.0551 1.43447 21.3919 0.389196C20.5059 0.915003 19.5366 1.28554 18.5258 1.4848C17.8452 0.761158 16.9452 0.281984 15.9648 0.121423C14.9845 -0.0391371 13.9786 0.127868 13.1028 0.596595C12.227 1.06532 11.53 1.80964 11.1198 2.71437C10.7097 3.61909 10.6091 4.63379 10.8337 5.60144C9.03915 5.51142 7.28361 5.04505 5.681 4.2326C4.07839 3.42015 2.66453 2.27978 1.5312 0.885516C0.954001 1.87918 0.777216 3.05547 1.03684 4.1749C1.29647 5.29433 1.97298 6.27272 2.92864 6.91088C2.2103 6.88983 1.50759 6.69604 0.88 6.34592V6.40312C0.880285 7.44523 1.24103 8.45516 1.90105 9.26162C2.56107 10.0681 3.47972 10.6214 4.5012 10.8278C3.83669 11.009 3.13947 11.0355 2.46312 10.9052C2.75166 11.802 3.31323 12.5862 4.0693 13.1482C4.82538 13.7102 5.73817 14.0219 6.68008 14.0398C5.74424 14.7754 4.67254 15.3192 3.5263 15.6403C2.38006 15.9613 1.18178 16.0532 0 15.9106C2.06418 17.2352 4.46593 17.9379 6.91856 17.9346" fill="currentColor"/>
                                                </svg>
                                            </a>
                                            <a href="/team" target='_blank' rel="noopener noreferrer">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 10.9371V17.556H14.1256V11.3407C14.1256 9.80708 13.5605 8.75775 12.1883 8.75775C11.139 8.75775 10.4933 9.48421 10.2511 10.1299C10.1704 10.3721 10.0897 10.695 10.0897 11.0986V17.556H6.21525C6.21525 17.556 6.29596 7.06268 6.21525 6.01336H10.0897V7.62771C10.574 6.82053 11.5426 5.69049 13.5605 5.69049C16.0628 5.69049 18 7.38555 18 10.9371ZM2.17937 0.443848C0.88789 0.443848 0 1.33174 0 2.46179C0 3.59183 0.807174 4.47972 2.09865 4.47972C3.47085 4.47972 4.27803 3.59183 4.27803 2.46179C4.35874 1.25102 3.55157 0.443848 2.17937 0.443848ZM0.242152 17.556H4.11659V6.01336H0.242152V17.556Z" fill="currentColor"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>                     */}
                    </Row>
                </div>
            </Container>
        </Layout>
    );
}

export default team;
